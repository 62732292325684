import images from '../assets';
const ErrorDevice = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center flex-col-reverse">
      <div className="flex flex-col justify-center items-center h-[100vh] px-[20px]">
        <img className="mb-[16px]" src={images.errorDino} alt="error" />
        <h1 className="text-white mb-[8px] text-center  text-[20px] font-[SFPro] font-bold">
          {text}
        </h1>
      </div>
    </div>
  );
};

export default ErrorDevice;
