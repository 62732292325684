import { twMerge } from "tailwind-merge";
const FilterTasks = ({
  typeVisibleTasks,
  setTypeVisibleTasks,
}: {
  typeVisibleTasks: string;
  setTypeVisibleTasks: (buttonType: string) => void;
}) => {
  function handleTypeVisibleTasks(buttonType: string) {
    if (buttonType === 'new') {
      if (typeVisibleTasks === 'new') {
        return;
      }
      setTypeVisibleTasks('new');
    } else {
      if (typeVisibleTasks === 'completed') {
        return;
      }

      setTypeVisibleTasks('completed');
    }
  }
  return (
    <div >
      <button
        className={twMerge("p-[12px] relative text-[#767676] font-bold text-[18px] font-[SFPro]", typeVisibleTasks === 'new' && 'text-[#F3F3F3] after:content-[""] after:absolute after:bottom-[0] after:h-[3px] after:left-[0] after:w-[100%] after:block after:bg-[#F3F3F3]')}
        onClick={() => {
          handleTypeVisibleTasks('new');
        }}
      >
        New
      </button>
      <button
        className={twMerge("p-[12px] relative text-[#767676] font-bold text-[18px] font-[SFPro]", typeVisibleTasks === 'completed' && 'text-[#F3F3F3] after:content-[""] after:absolute after:bottom-[0] after:h-[3px] after:left-[0] after:w-[100%] after:block after:bg-[#F3F3F3]')}
        onClick={() => {
          handleTypeVisibleTasks('completed');
        }}
      >
        Completed
      </button>
    </div>
  );
};

export default FilterTasks;
