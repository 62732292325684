import { twMerge } from 'tailwind-merge';
import { TasksIcon } from './navigationIcons/Tasks';
import { FriendsIcon } from './navigationIcons/Friends';
import { ShakeIcon } from './navigationIcons/Shake';
import { TournamentsIcon } from './navigationIcons/Tournaments';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotificationStore } from '../../shared/hooks/useNotification';
import { useCoinsStore } from '../../shared/hooks/useCoinStore';
import { useTelegramInfo } from '../hooks/useTelegramInfo';

// Consider moving to routerObject
const navigationItems = [
  {
    link: '/tournaments',
    name: 'Tournaments',
    icon: TournamentsIcon,
  },
  {
    link: '/earn',
    name: 'Earn',
    icon: ShakeIcon,
  },
  {
    link: '/tasks',
    name: 'Tasks',
    icon: TasksIcon,
  },
  {
    link: '/friends',
    name: 'Friends',
    icon: FriendsIcon,
  },
];

const NavigationMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { tasksNotification } = useNotificationStore();
  const { requestAccess } = useCoinsStore();
  const { telegramVersion } = useTelegramInfo();

  return (
    <div className="bg-[#1A1A1A] h-[10%] flex flex-col z-20 font-[SFPro]">
      <div className="flex justify-between">
        {navigationItems.map(({ name, link, icon: Icon }) => (
          <div
            key={name}
            onClick={() => {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              navigate(link);
              if (!telegramVersion || (telegramVersion && telegramVersion < 8)) {
                requestAccess();
              }
            }}
            className="w-[33%] flex justify-center mt-2"
          >
            <div className="flex flex-col items-center relative">
              {name === 'Tasks' && tasksNotification && (
                <span className="bg-[#BEF73D] rounded-full w-[7px] h-[7px] absolute -top-[2px] -right-[2px]"></span>
              )}
              <Icon size={24} isActive={pathname.includes(link) && link !== '/'} />
              <div
                className={twMerge(
                  'text-[#868686] text-[10px]',
                  pathname.includes(link) && link !== '/' && 'text-white',
                )}
              >
                {name}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default NavigationMenu;
