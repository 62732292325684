import { useEffect, useState } from 'react';
import Router from './Router';
import api from '../shared/utils/api';

import ErrorDevice from '../modules/ErrorDevice';
import useScreenOrientation from './hooks/useOrientationDetection';
import { useTelegramInfo } from './hooks/useTelegramInfo';

function App() {
  const [referralSent, setReferralSent] = useState(false);
  const deviceOrientation = useScreenOrientation();
  const { telegramVersion } = useTelegramInfo();
  const isMobile = /Mobile|Android|iPhone|iPad/i.test(navigator.userAgent);
  const isAdmin = ['dev-local', 'dev'].includes(import.meta.env.VITE_MODE);

  const lockDeviceOrientation = () => {
    Telegram.WebApp.ready();
    if (telegramVersion && telegramVersion >= 8 && window.Telegram?.WebApp?.lockOrientation) {
      window.Telegram.WebApp.lockOrientation();
    } else {
      console.error('Telegram WebApp API is not available.');
    }
  };

  const getUserDeviceLocation = async () => {
    try {
      const resp = await fetch('https://ipapi.co/json/').then((response) => response.json());

      return { country: resp.country_name, city: resp.city } as Record<string, string>;
    } catch {
      return '';
    }
  };

  useEffect(() => {
    async function initWebApp() {
      const WebApp = window?.Telegram?.WebApp;

      // Initiate the WebApp
      WebApp.ready();

      WebApp.disableVerticalSwipes();
      WebApp.enableClosingConfirmation();

      // Access theme params
      const themeParams = WebApp?.themeParams || {};

      WebApp.headerColor = '#000000';

      // Override the secondary background color
      document.documentElement.style.setProperty(
        '--tg-theme-secondary-bg-color',
        themeParams.secondary_bg_color || '#000000',
      );

      const data =
        WebApp.initData ||
        (import.meta.env.VITE_MODE === 'dev-local' ? import.meta.env.VITE_AUTH : '');

      window.Telegram?.WebApp?.expand();

      localStorage.setItem('accessToken', data);

      if (!localStorage.getItem('user_location')) {
        try {
          const location = await getUserDeviceLocation();
          localStorage.setItem('user_location', JSON.stringify(location));
        } catch (err) {
          throw new Error(`Could not get location: ${JSON.stringify(err)}`);
        }
      }
    }
    initWebApp();
  }, []);

  useEffect(() => {
    const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;
    if (!referralSent && startParam) {
      setReferralSent(true);
      api.post('/users/set_referral', {
        is_premium: window.Telegram.WebApp.initDataUnsafe.user?.is_premium || false,
        referral: startParam,
      });
    }
  }, []);

  if (!isAdmin && !window.Telegram?.WebApp?.initData) {
    return <ErrorDevice text={'Please open the app in Telegram for the best experience.'} />;
  }
  if (!isAdmin && !isMobile) {
    return (
      <ErrorDevice
        text={'Laptop feeling boring. Try exploring on mobile for a fresh experience!'}
      />
    );
  }

  if (deviceOrientation && isMobile && deviceOrientation.includes('landscape')) {
    return (
      <ErrorDevice text={'For best experience, please turn your phone to a vertical orientation'} />
    );
  }

  if (deviceOrientation && isMobile && deviceOrientation.includes('portrait')) {
    lockDeviceOrientation();
  }

  return (
    <>
      <Router />
    </>
  );
}

export default App;
