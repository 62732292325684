import Coin from './coin.png';
import CoinGreen from './coinGreen.svg';
import next from './next.svg';
import bgOnboarding from './onboardingbg.png';
import coin_reward from './coin_reward.svg';
import shakes_multiplier from './shakes_multiplier.svg';
import tournament_multiplier from './tournament_multiplier.svg';
import futureRewards from './futureRewards.svg';
import completeDaily from './completeDaily.svg';
import dailyCoin from './dailyCoin.svg';
import referralMultiplier from './referalMultiplier.svg';
import errorDino from './ErrorDino.svg';
import coinTasks from './coinTasks.svg';
import complete from './complete.svg';
import arrowRight from './arrow-right.svg'

export default {
  arrowRight,
  complete,
  coinTasks,
  errorDino,
  Coin,
  CoinGreen,
  next,
  bgOnboarding,
  coin_reward,
  shakes_multiplier,
  tournament_multiplier,
  futureRewards,
  completeDaily,
  dailyCoin,
  referralMultiplier,
};
