import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type NotificationStore = {
  tasksNotification: boolean;
  setTasksNotification: (flag: boolean) => void;
};

export const useNotificationStore = create(
  persist<NotificationStore>(
    (set) => ({
      tasksNotification: false,
      setTasksNotification: (flag: boolean) => set(() => ({ tasksNotification: flag })),
    }),
    {
      name: 'notification_store', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
