import { useNavigate } from 'react-router-dom';
import images from '../../assets';
import { twMerge } from 'tailwind-merge';
import { useQueryClient } from 'react-query';
import { ITask } from '../../app/hooks/useTasks';
import { useSnackbar } from 'notistack';
const ActionButton = ({
  setIsOpen,
  task,
  status,
  category,
  id,
  setCurrentModalTask,
  isOneTime,
}: {
  isOneTime: boolean;
  setIsOpen: (type: boolean) => void;
  setCurrentModalTask: (type: ITask) => void;
  status: string;
  category: string;
  id: number;
  task: ITask;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const buttonStatus = (() => {
    switch (status) {
      case 'APPROVED':
      case 'APPROVED_AUTO':
        return 'approved';
      case 'CLAIMED':
        return 'claimed';
      case 'AVAILABLE':
      case 'IN_PROGRESS':
      case 'REJECTED':
      case 'SUBMITTED':
        return 'inProgress';
      default:
        return '';
    }
  })();

  const buttonText =
    status === 'APPROVED' || status === 'APPROVED_AUTO'
      ? 'claim'
      : ['AVAILABLE', 'IN_PROGRESS', 'REJECTED', 'SUBMITTED'].includes(status) &&
          category === 'LINK_UPLOAD'
        ? 'open'
        : 'Go';
  const isModal =
    ['AVAILABLE', 'IN_PROGRESS', 'REJECTED', 'SUBMITTED'].includes(status) &&
    category === 'LINK_UPLOAD'
      ? 'open'
      : 'close';

  function actionBtn() {
    if (category === 'INTEGRATION' && buttonStatus !== 'approved') {
      try {
        setTimeout(() => {
          fetch(`${import.meta.env.VITE_API_URL}/tasks/${id}/complete`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }).then(() => {
            queryClient.invalidateQueries(['tasks']);
          });
        }, 5000);
      } catch (error) {
        enqueueSnackbar('Something gone wrong!', {
          autoHideDuration: 1000,
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        throw new Error(`Tasks: ${JSON.stringify(error)}`);
      }
      return;
    }
    if (buttonStatus === 'inProgress') {
      if (isModal === 'open') {
        setIsOpen(true);
        return;
      }
      if (category === 'SHAKES') {
        navigate('/earn');
      } else if (category === 'TOURNAMENT' || category === 'TOURNAMENT_ACTION') {
        navigate('/tournaments');
      } else if (category === 'REFERRAL') {
        navigate('/friends');
      }
    } else if (buttonStatus === 'approved') {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
      try {
        fetch(`${import.meta.env.VITE_API_URL}/tasks/${id}/claim`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }).then(() => {
          queryClient.invalidateQueries(['tasks']);

          enqueueSnackbar('Claimed!', {
            autoHideDuration: 1000,
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        });
      } catch (error) {
        throw new Error(`Tasks: ${JSON.stringify(error)}`);
      }
    }
  }

  return (
    <a
      href={
        category === 'INTEGRATION' && buttonStatus === 'inProgress' ? task.action_link : undefined
      }
      target="_blank"
      onClick={() => {
        setCurrentModalTask(task);
        actionBtn();
      }}
      className={twMerge(
        'bg-[#FFFFFF] shrink-0 max-w-[70px]  flex gap-[10px] justify-center items-center   text-[#000000] font-semibold rounded-[24px] text-[16px] font-[SFPro]',
        buttonStatus === 'approved' && 'bg-[#BEF73D]',
        buttonStatus === 'claimed' && 'bg-[#434343] w-[60px] h-[32px]',
        isOneTime ? 'capitalize w-[70px] px-[10px] py-[4px]' : 'uppercase px-[12px] py-[8px]',
      )}
    >
      {buttonStatus === 'claimed' ? <img src={images.complete}></img> : buttonText}
      {buttonStatus === 'inProgress' && isModal === 'close' && (
        <img style={{ width: '10px', height: '10px' }} src={images.arrowRight} />
      )}
    </a>
  );
};

export default ActionButton;
