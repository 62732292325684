import { useState, useEffect } from 'react';

const getOrientation = () => {
  if (typeof window !== 'undefined' && window.screen && window.screen.orientation) {
    return window.screen.orientation.type;
  } else {
    // Fallback
    if (typeof window !== 'undefined') {
      return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    }
  }
  return null;
};

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState<null | string>(getOrientation());

  const updateOrientation = () => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.screen && window.screen.orientation) {
      window.screen.orientation.addEventListener('change', updateOrientation);
      return () => {
        window.screen.orientation.removeEventListener('change', updateOrientation);
      };
    } else if (typeof window !== 'undefined') {
      // Fallback
      window.addEventListener('resize', updateOrientation);
      return () => {
        window.removeEventListener('resize', updateOrientation);
      };
    }
  }, []);

  return orientation;
};

export default useScreenOrientation;
