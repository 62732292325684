import * as Tabs from '@radix-ui/react-tabs';

// import TournamentsList from '../TournamentsList';
import LeadersList from '../LeadersList';
import { useLocation, useMatches, useNavigate } from 'react-router-dom';
import { lazy, Suspense, useMemo } from 'react';
import * as ScrollArea from '@radix-ui/react-scroll-area';

const TournamentsList = lazy(() => import('../TournamentsList'));
const ListSkeleton = () => {
  return (
    <div className="w-full h-full flex flex-col gap-2">
      <div className="animate-pulse bg-[#121212] w-full h-[142px] rounded-[16px]"></div>
      <div className="animate-pulse bg-[#121212] w-full h-[142px] rounded-[16px]"></div>
    </div>
  );
};
export const Trigger = ({
  name,
  onClick,
  value,
  notification,
}: {
  name: string;
  onClick: () => void;
  value: string;
  notification?: boolean;
}) => {
  return (
    <Tabs.Trigger
      className={
        'text-[#767676] relative font-bold px-5 h-[45px] border-b-4 border-b-transparent flex items-center justify-center text-[18px] leading-none select-none first:rounded-tl-md last:rounded-tr-md data-[state=active]:border-b-[#F3F3F3] data-[state=active]:border-b-4 data-[state=active]:text-[#F3F3F3]  data-[state=active]:focus:relative  outline-none cursor-default'
      }
      value={value}
      onClick={onClick}
    >
      {name}

      {notification && (
        <span className="bg-[#BEF73D] rounded-full w-[8px] h-[8px] absolute top-[6px] right-[8px]"></span>
      )}
    </Tabs.Trigger>
  );
};

const TabsMenu = () => {
  const matches = useMatches();
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useMemo(
    // @ts-ignore
    () => matches.find((match) => match.pathname === location.pathname)?.handle?.title,
    [location],
  );

  return (
    <Tabs.Root
      className="flex flex-col w-full shadow-blackA2 h-[calc(100%-258px)]"
      defaultValue="tournaments"
    >
      <Tabs.List className="shrink-0 flex font-[SFPro] font-bold" aria-label="Tab">
        <Trigger value="tournaments" name={activeTab} onClick={() => navigate('/tournaments')} />
        <Trigger name="Leaders" value="leaders" onClick={() => {}} />
      </Tabs.List>
      <ScrollArea.Root className="overflow-auto">
        <ScrollArea.Viewport className="size-full">
          <Tabs.Content
            className="grow rounded-b-md pt-4 outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
            value="tournaments"
          >
            <Suspense fallback={<ListSkeleton />}>
              <TournamentsList />
            </Suspense>
          </Tabs.Content>
          <Tabs.Content
            className="grow p-5rounded-b-md outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
            value="leaders"
          >
            <LeadersList />
          </Tabs.Content>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar orientation="vertical"></ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </Tabs.Root>
  );
};

export default TabsMenu;
