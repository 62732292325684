import { useQuery } from 'react-query';
import api from '../../shared/utils/api';

export type ITask = {
  id: number;
  name: string;
  description: string;
  type: string;
  category: string;
  order: number;
  difficulty: string;
  icon: string | null;
  goal_value: number;
  image_url: string | null;
  rewards: {
    coins_reward: number;
  };
  user_tasks: {
    id: number;
    status: string;
    actions_done: number;
    submitted_result: string | null;
  };
  url_pattern: string | null;
  action_link: string;
};

export type Tasks = {
  daily_tasks: Array<ITask>;
  one_time_tasks: Array<ITask>;
};

export const useTasks = () => {
  return useQuery<Tasks>({
    queryKey: ['tasks'],
    queryFn: async () => {
      const res = await api.get<Tasks>('/tasks/');
      return res;
    },
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchInterval: false,
  });
};
