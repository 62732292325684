import { Profile, useProfile } from '../../app/hooks/useProfile';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useMutation, useQueryClient } from 'react-query';
import { IShake, IShakeResponse } from '../../modules/shake/ShakePage';
import api from '../utils/api';
import { useEffect } from 'react';

interface CoinsStore {
  //states
  shakesDetected: number;
  coinsFromShaking: number;
  shouldUpdate: boolean;
  shakeStartedTimestamp: Date | null;

  // setters
  addShake: (multiplier: number) => void;
  setShakesDetected: (shakesDetected: number) => void;
  requestAccess: () => void;
  setShouldUpdate: (shouldUpdate: boolean) => void;
  setCoinsFromShaking: (multiplier: number) => void;
  clearCoinsFromShaking: () => void;
  setShakeStartedTimestamp: (date: Date | null) => void;
}

const isFeatureSupported = () => {
  return (
    typeof DeviceMotionEvent !== 'undefined' &&
    typeof (DeviceMotionEvent as unknown as DeviceMotionEventiOS).requestPermission === 'function'
  );
};

interface DeviceMotionEventiOS extends DeviceMotionEvent {
  requestPermission?: () => Promise<'granted' | 'denied'>;
}

export const usePersistCoinsStore = () => {
  const store = useCoinsStore();
  const { shouldUpdate, setShouldUpdate, shakeStartedTimestamp, setShakeStartedTimestamp } =
    useCoinsStore();
  const { refetch, onSuccess, onError, data: UserData } = useProfile();

  const queryClient = useQueryClient();

  const { mutateAsync: submitShakes, isLoading } = useMutation({
    mutationFn: (data: IShake) => api.post<IShakeResponse>('/shakes/', data),
    onSuccess: async () => {
      queryClient.invalidateQueries(['profile']);
      setShakeStartedTimestamp(null);

      try {
        const profile = await refetch();
        onSuccess(profile.data as Profile);
      } catch {
        onError();
      }
    },
  });

  useEffect(() => {
    if (shouldUpdate && !isLoading) {
      async function handleUpdate() {
        let phoneShakes = store.coinsFromShaking;

        const multiplier = UserData?.active_multipliers?.shakes_multiplier || 1;

        if (multiplier) {
          phoneShakes = phoneShakes / multiplier;
        }

        await submitShakes({
          total_shakes: store.shakesDetected + phoneShakes,
          coins: store.coinsFromShaking,
          shakes: phoneShakes, //how many times user shacked their phone
          start_datetime: shakeStartedTimestamp || new Date(),
          end_datetime: new Date(),
        });
      }

      setShouldUpdate(false);
      handleUpdate();
    }
  }, [shouldUpdate]);

  return {
    ...store,
  };
};

export const useCoinsStore = create(
  persist<CoinsStore>(
    (set) => ({
      shakesDetected: 0,
      coinsFromShaking: 0,
      shouldUpdate: false,
      shakeStartedTimestamp: null,

      setShouldUpdate: (shouldUpdate: boolean) => set({ shouldUpdate }, undefined),

      setShakeStartedTimestamp: (date: Date | null) =>
        set({ shakeStartedTimestamp: date }, undefined),

      setCoinsFromShaking: (multiplier: number) =>
        set((state) => ({ coinsFromShaking: state.coinsFromShaking + multiplier })),

      clearCoinsFromShaking: () => set(() => ({ coinsFromShaking: 0 })),

      setShakesDetected: (shakesDetected: number) => set(() => ({ shakesDetected })),

      addShake: (multiplier: number) =>
        set((prev) => ({ shakesDetected: prev.shakesDetected + multiplier })),

      requestAccess: async () => {
        if (isFeatureSupported()) {
          // IOS
          const deviceMotionEvent = DeviceMotionEvent as unknown as DeviceMotionEventiOS;

          if (typeof deviceMotionEvent.requestPermission === 'function') {
            let permission: PermissionState;
            try {
              permission = await deviceMotionEvent.requestPermission();
            } catch {
              return false;
            }
            if (permission !== 'granted') {
              return false;
            }
          }

          return true;
        } else {
          return true;
        }
      },
    }),
    {
      name: 'coins',
    },
  ),
);
