import { useState, useRef, useEffect } from 'react';
import FilterTasks from './FilterTasks';
import images from '../../assets';
import { ITask } from '../../app/hooks/useTasks';
import ActionButton from './ActionButton';
const OneTimeTasks = ({
  setIsOpen,
  tasks,
  setCurrentModalTask,
}: {
  setIsOpen: (type: boolean) => void;
  setCurrentModalTask: (type: ITask) => void;
  tasks: Array<ITask>;
}) => {
  const [typeVisibleTasks, setTypeVisibleTasks] = useState('new');
  const containerRef = useRef<HTMLUListElement>(null);

  const filteredTasks = tasks.filter((task) => {
    if (typeVisibleTasks === 'new') {
      return task.user_tasks.status !== 'CLAIMED';
    } else {
      return task.user_tasks.status === 'CLAIMED';
    }
  });

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };
  useEffect(() => {
    scrollToTop();
  }, [typeVisibleTasks]);

  return (
    <div className="h-full overflow-hidden w-full ">
      <FilterTasks typeVisibleTasks={typeVisibleTasks} setTypeVisibleTasks={setTypeVisibleTasks} />
      <ul className="overflow-y-auto no-scrollbar h-full pb-[345px] pt-[16px]" ref={containerRef}>
        {filteredTasks.map((task) => {
          const percent = task.goal_value
            ? (task.user_tasks.actions_done / task.goal_value) * 100
            : null;

          return (
            <li
              key={task.name + task.id}
              className="bg-[#1A1A1A] mb-[8px]  px-[16px] py-[8px] flex flex-col gap-2 rounded-[16px] "
            >
              <div className="gap-[8px] flex justify-between items-center">
                <div>
                  <p className="text-[#FFFFFFBF] leading-[24px] font-normal text-[16px] font-[SFPro]">
                    {task.name}
                  </p>
                  <p className="flex text-[#FFFFFF80] gap-[2px] items-center leading-[16px] font-normal text-[12px] font-[SFPro]">
                    +{task.rewards.coins_reward}{' '}
                    <img style={{ width: '12px', height: '12px' }} src={images.Coin} />
                  </p>
                </div>

                <ActionButton
                  isOneTime={true}
                  task={task}
                  setCurrentModalTask={setCurrentModalTask}
                  id={task.id}
                  setIsOpen={setIsOpen}
                  status={task.user_tasks.status}
                  category={task.category}
                />
              </div>
              {percent !== null && task.user_tasks.status !== 'CLAIMED' && (
                <div className="bg-[#282828] mt-[12px] rounded-[16px] h-[8px] p-[1px]">
                  <div
                    style={{ width: `${percent > 100 ? 100 : percent}%` }}
                    className="bg-[#BEF73D]  rounded-[16px] h-[6px]"
                  ></div>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OneTimeTasks;
