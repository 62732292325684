import DailyTasks from './DailyTasks';
import OneTimeTasks from './OneTimeTasks';
import { Sheet } from 'react-modal-sheet';
import { useForm, UseFormRegister, SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { patterns } from '../../shared/constants/patterns';
import { useClickAway } from '@uidotdev/usehooks';
import { twMerge } from 'tailwind-merge';
import images from '../../assets';
import { ITask, useTasks } from '../../app/hooks/useTasks';
import { useQueryClient } from 'react-query';
import { useNotificationStore } from '../../shared/hooks/useNotification';

const initObject = {
  id: 2,
  name: '500 Shakes per day',
  description: '500 Shakes per day',
  type: 'ONE_TIME',
  category: 'SHAKES',
  order: 0,
  difficulty: 'EASY',
  icon: null,
  image_url: '',
  goal_value: 500,
  rewards: {
    coins_reward: 100,
  },
  user_tasks: {
    id: 23,
    status: 'IN_PROGRESS',
    actions_done: 216,
    submitted_result: '',
  },
  url_pattern: null,
  action_link: 'link',
};

const TasksPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const { data, isLoading } = useTasks();
  const queryClient = useQueryClient();
  const [currentModalTask, setCurrentModalTask] = useState<ITask>(initObject);
  const ref = useClickAway(() => {
    setIsOpen(false);
  });
  const { setTasksNotification } = useNotificationStore();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormValues>({
    values: { link: currentModalTask.user_tasks.submitted_result || '' },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setTasksNotification(false);
  }, []);

  useEffect(() => {
    reset({ link: currentModalTask.user_tasks.submitted_result || '' });
  }, [currentModalTask]);
  const onSubmit: SubmitHandler<IFormValues> = (values: IFormValues) => {
    try {
      fetch(`${import.meta.env.VITE_API_URL}/tasks/${currentModalTask.id}/complete`, {
        method: 'POST',
        body: JSON.stringify({ result_url: values.link }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        queryClient.invalidateQueries(['tasks']);
      });
    } catch (error) {
      throw new Error(`Onboarding: ${JSON.stringify(error)}`);
    }
    setCurrentModalTask(initObject);
    setIsOpen(false);
    reset({ ...values });
  };

  if (!data || isLoading) {
    return (
      <div className=" h-full w-full py-4 ">
        <div className="bg-[#1A1A1A] rounded-2xl h-full w-full animate-pulse"></div>
      </div>
    );
  }

  interface IFormValues {
    link: string;
  }

  type InputProps = {
    register: UseFormRegister<IFormValues>;
    required: boolean;
    placeholder: string;
    name: string;
    label: string;
    // onFocus?: () => void;
    // onBlur?: () => void;
    error?: string;
  };

  const Input = ({ register, required, placeholder, name, label, error, ...props }: InputProps) => {
    return (
      <div className={twMerge('flex flex-col gap-2')}>
        <div className="flex justify-between items-center font-[SFPro]">
          <label className="text-[26px] text-[#FFFFFF] font-medium">Link</label>
          <p
            className={twMerge(
              'text-[12px] text-[#29CC6A] font-medium',
              currentModalTask.user_tasks.status === 'SUBMITTED' && 'text-[#FF9500]',
              currentModalTask.user_tasks.status === 'REJECTED' && 'text-[#FC5555]',
            )}
          >
            {currentModalTask.user_tasks.status === 'SUBMITTED'
              ? 'IN REVIEW'
              : currentModalTask.user_tasks.status === 'REJECTED'
                ? 'REJECTED'
                : ''}
          </p>
        </div>
        <div className="flex flex-col">
          <input
            onFocus={() => {
              setFocused(true);
            }}
            autoFocus={isFocused}
            placeholder={placeholder}
            className={twMerge(
              'bg-[#1A1A1A] text-[#F0F0F0] p-3 rounded-[8px] font-[Inter] text-[16px] font-normal outline-none',

              error && 'focus:outline-[#FC5555]',
              !error && 'focus:outline-[#29CC6A]',
            )}
            {...register('link', {
              required: 'This field is required',
              pattern: {
                value: currentModalTask.url_pattern
                  ? new RegExp(currentModalTask.url_pattern)
                  : patterns.url,
                message: 'Please enter a valid URL',
              },
            })}
            {...props}
            onBlur={() => setFocused(false)}
          />
          <span className="text-[#FC5555] text-[10px] pt-1 pl-1">{error}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full">
      <DailyTasks
        tasks={data?.daily_tasks}
        setCurrentModalTask={setCurrentModalTask}
        setIsOpen={setIsOpen}
      />
      <OneTimeTasks
        tasks={data?.one_time_tasks}
        setCurrentModalTask={setCurrentModalTask}
        setIsOpen={setIsOpen}
      />

      <Sheet
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        detent={isFocused ? 'full-height' : 'content-height'}
        className={isFocused ? 'full-sheet' : ''}
      >
        <Sheet.Container ref={ref}>
          <Sheet.Header />
          <Sheet.Content style={{ flexGrow: '0' }} className="justify-start p-[16px]">
            <div className="bg-[#121212] mb-[40px] flex-col flex justify-between  text-[16px] leading-[16px] text-[#FFFFFF] rounded-[16px] h-[100%]">
              <div className="pr-[50px]">
                <p className=" mb-[12px] font-bold text-[24px] font-[SFPro]">
                  {currentModalTask?.name}
                </p>
                <p className="flex text-[#FFFFFFBF] gap-[2px] items-center">
                  +{currentModalTask.rewards.coins_reward}
                  <img style={{ width: '16px', height: '16px' }} src={images.Coin} />
                </p>
              </div>
              <img
                src={currentModalTask.image_url || images.coin_reward}
                style={{ width: '41px', height: '41px' }}
                className="absolute top-[18px] right-[18px]"
                alt=""
              />
            </div>
            <p className="text-[#FFFFFF] whitespace-normal break-words font-normal text-[16px] mb-[20px] font-[SFPro]">
              {currentModalTask.description}
            </p>
            {currentModalTask.action_link && (
              <a
                href={currentModalTask.action_link}
                className="truncate shrink-0 bg-[#BEF73D] text-center px-[20px] py-[8px] mb-[20px]  text-[#000000] font-semibold rounded-[24px] text-[16px] font-[SFPro]"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Link
              </a>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="pb-4">
              <div className="flex flex-col gap-4">
                <Input
                  name={'link'}
                  label={'link'}
                  register={register}
                  placeholder={'Paste URL here...'}
                  required={true}
                  error={errors.link?.message}
                />
              </div>
              <button
                disabled={!isDirty || !isValid}
                type="submit"
                className={twMerge(
                  'bg-[#FFFFFF] text-[#2C2C2C] border-[1px] border-[#2C2C2C] rounded-[10px] text-[16px] w-full p-2 mt-4 mb-4 font-semibold',
                  (!isDirty || !isValid) && 'bg-transparent',
                )}
              >
                Save
              </button>
            </form>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
};

export default TasksPage;
