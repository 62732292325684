import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import images from '../../assets';
import { ITask } from '../../app/hooks/useTasks';
import ActionButton from './ActionButton';
const DailyTasks = ({
  setIsOpen,
  tasks,
  setCurrentModalTask,
}: {
  setIsOpen: (type: boolean) => void;
  setCurrentModalTask: (type: ITask) => void;
  tasks: Array<ITask>;
}) => {
  if (!tasks) {
    return;
  }

  const filter = (a: ITask, b: ITask) => {
    if (a.user_tasks.status === 'CLAIMED' && b.user_tasks.status !== 'CLAIMED') {
      return 1;
    } else if (a.user_tasks.status !== 'CLAIMED' && b.user_tasks.status === 'CLAIMED') {
      return -1;
    }
    return 0;
  };
  return (
    <div className="pt-[20px] pb-[40px]">
      <h2 className="text-[#F3F3F3] mb-[16px] font-bold text-[24px] font-[SFPro]">Daily tasks</h2>
      <Swiper
        grabCursor={true}
        slidesPerView={'auto'}
        spaceBetween={16}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper tasks"
        pagination={true}
        style={{
          width: '100%',
          height: 'auto',
          backgroundColor: 'transparent',
          paddingRight: '20px',
        }}
      >
        {tasks.sort(filter).map((task: ITask) => {
          const percent = task.goal_value
            ? (task.user_tasks.actions_done / task.goal_value) * 100
            : null;

          return (
            <SwiperSlide
              style={{
                background:
                  'linear-gradient(80.54deg, #272D18 3.41%, #d7ff7a9c 48.24%, #bef73d33 95.7%)',
              }}
              className="relative p-[1px] rounded-[16px] h-[180px] w-[297px]"
              key={task.name + task.id}
            >
              <div className="bg-[#121212] flex-col flex justify-between text-[16px] leading-[16px] text-[#FFFFFF] rounded-[16px] h-[100%] p-[16px]">
                <div className="pr-[50px]">
                  <p className=" mb-[12px] font-bold text-[16px] font-[SFPro]">{task.name}</p>
                  <p className="flex text-[#FFFFFFBF] gap-[2px] items-center">
                    +{task.rewards.coins_reward}{' '}
                    <img style={{ width: '16px', height: '16px' }} src={images.coinTasks} />
                  </p>
                </div>
                <img
                  src={task.image_url || images.coin_reward}
                  style={{ width: '41px', height: '41px' }}
                  className="absolute top-[18px] right-[18px]"
                  alt=""
                />
                <div>
                  <ActionButton
                    isOneTime={false}
                    task={task}
                    setCurrentModalTask={setCurrentModalTask}
                    id={task.id}
                    setIsOpen={setIsOpen}
                    status={task.user_tasks.status}
                    category={task.category}
                  />
                  {percent !== null && task.user_tasks.status !== 'CLAIMED' && (
                    <div className="bg-[#282828] mt-[12px] rounded-[16px] h-[8px] p-[1px]">
                      <div
                        style={{ width: `${percent > 100 ? 100 : percent}%` }}
                        className="bg-[#BEF73D]  rounded-[16px] h-[6px]"
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default DailyTasks;
