import { useEffect, useState } from 'react';

export function useTelegramInfo() {
  const [userAgent, setUserAgent] = useState<string | null>(null);
  const [telegramVersion, setTelegramVersion] = useState<number | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUserAgent(navigator.userAgent);

      if (window.Telegram && window.Telegram.WebApp) {
        const versionString = window.Telegram.WebApp.version;
        const versionNumber = parseFloat(versionString);
        setTelegramVersion(versionNumber);
      } else {
        setTelegramVersion(null);
      }
    }
  }, []);

  return { userAgent, telegramVersion };
}
